import {
  AnswerTypes,
  FilterOptions,
  HomeWorkSummaryDetails,
  StudentHomeworkViewType
} from '@features/homework-management/constants';
import { AnswerStatus } from '@constants/answer';

import { ButtonVariant } from './button';
import { BarChartType, PieChartType } from './chart';

export type LabelVarient = 'ongoing' | 'completed';
export type LabelSize = 'small' | 'large';

export interface HomeworkLabelProps {
  size?: LabelSize;
  label: LabelVarient;
  className?: string;
}

export interface HomeworkDetailsType {
  title: string;
  deadline: Date | null;
  class: any;
}

export interface DistributeHomeworkProps {
  onDistributeConfirm: () => void;
  onInputChange: (field: string, value: string | number) => void;
  isDistributeButtonDisabled: boolean;
  homeworkDetails: HomeworkDetailsType;
  isLoading: boolean;
}

export enum CardType {
  default = 'DEFAULT',
  radio = 'RADIO'
}

export interface QuestionCardProps {
  details: any;
  onDetailsClick?: () => void;
  onChangeClick?: () => void;
  replaceQuestion?: () => void;
  type?: CardType;
  isSelected?: boolean;
  handleSelect?: () => void;
  index?: number;
  detailsClassName?: string;
  chipClassName?: string;
}

export enum ReplaceQuestionComponentType {
  replace = 'REPLACE',
  change = 'CHANGE'
}

export interface ReplaceQuestionProps {
  onClose: () => void;
  onSelect: (item: any) => void;
  type?: ReplaceQuestionComponentType;
  currentQuestion: any;
  recommendedQuestions: any[];
}

export interface Attachment {
  name: string;
  extension: string;
  path: string;
  url: string;
}

export interface QuestionAttachment {
  createdAt: string | null;
  updatedAt: string | null;
  id: string;
  questionId: string;
  attachmentId: string;
  type: string;
  notes: string | null;
  attachments: Attachment;
}

export interface AnswerAttachment {
  createdAt: string | null;
  updatedAt: string | null;
  id: string;
  answerId: string;
  attachmentId: string;
  type: string;
  notes: string | null;
  attachments: Attachment;
}

export interface Answer {
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  id: string;
  questionId: string;
  answer: string | null;
  isCorrect: boolean;
  createdBy: string;
  updatedBy: string;
  answerAttachments: AnswerAttachment[];
}

export interface Skill {
  id: string;
  name: string;
}

export interface QuestionSkill {
  skillId: string;
  skills: Skill;
}

export interface SolutionAttachment {
  name: string;
  extension: string;
  path: string;
  url: string;
}

export interface AnswerSolution {
  createdAt: string;
  updatedAt: string;
  id: string;
  solution: string | null;
  attachmentId: string;
  attachmentType: string;
  attachmentNotes: string | null;
  questionId: number;
  attachments: SolutionAttachment;
}

export interface Topic {
  id: string;
  name: string;
}

export interface Subject {
  id: string;
  name: string;
}

export interface QuestionDetailProps {
  questionDetails: any;
}

export interface TopicDetails {
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  id: string;
  name: string;
  description: string;
  subjectId: string;
}

export interface SkillAndTopic {
  value: string;
  label: string;
  id: string;
  name: string;
  description: string;
  topicId: string;
  topics: TopicDetails;
}

export interface ErrorDetail {
  isError: boolean;
  errorMessage: string;
}

export interface ErrorState {
  [key: string]: ErrorDetail;
}

export interface SubjectLevel {
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  id: string;
  name: string;
  level: number;
}

export interface SubjectDetails {
  value: string;
  label: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  id: string;
  teacherId: string;
  classId: string;
  subjectId: string;
  startDate: string;
  endDate: string;
  isActive: boolean;
  subject: Subject;
}

export interface DropDownItem {
  id: string | number;
  label?: string;
  value?: string;
}

export interface TopicAndSkills {
  id: string;
  label: string;
  value: string;
  skills: DropDownItem;
  topic: DropDownItem;
}

export interface SubjectAndLevels {
  id: string;
  label: string;
  value: string;
  subjects: DropDownItem;
  levels: DropDownItem;
}

export interface SortDropDownOption {
  topicAndSkills: TopicAndSkills[];
  subjectAndLevels: SubjectAndLevels[];
}

export type HomeworkSummaryType =
  | HomeWorkSummaryDetails.TOTAL_CLASSES
  | HomeWorkSummaryDetails.UPCOMINGDEADLINES
  | HomeWorkSummaryDetails.TOTAL_HOMEWORKS;

export type FilterTypes =
  | FilterOptions.SUBJECT
  | FilterOptions.SUBJECT_AND_LEVELS
  | FilterOptions.TOPIC_AND_SKILLS;

export interface ReviewCardProps {
  question: any;
  questionNo?: number;
  className?: string;
  handleResultView?: (
    type: StudentHomeworkViewType,
    questionId: string | number
  ) => void;
  strictModalType?: StudentHomeworkViewType;
}

export interface AnswerStatusProps {
  status: AnswerStatus;
  className?: string;
}
export interface ResultCardProps {
  type: AnswerStatus;
}

export interface QuestionSummary {
  mark: number;
  maximumMark: number;
  questionId: string;
  result: AnswerStatus;
  type: string;
}

export interface ResultProps {
  type: StudentHomeworkViewType | string;
  buttonVariant?: ButtonVariant;
  buttonLabel?: string;
  questionId?: string | number;
  assignmentId?: string;
  studentId?: string;
  handleCloseModal: () => void;
  studentName?: string;
  questionIdSet: string[];
  strictModalType?: StudentHomeworkViewType;
}

export interface StudentHomeworkSummaryProps {
  assignmentId: string;
  studentId: string;
  studentName?: string;
  isOpen: boolean;
  handleClose: () => void;
  strictModalType?: StudentHomeworkViewType;
  summaryTitle?: string;
}

export interface StudentResultViewProps {
  homeworkAttemptDetails: any;
  questionDetails: any;
  buttonDetails: {
    buttonVariant: ButtonVariant;
    buttonLabel: string;
  };
  onButtonClick: () => void;
  isDetailsEdit?: string;
  setIsDetailsEdit?: (value: string) => void;
  teacherInput?: {
    marks?: string | number;
    feedback?: string | number;
    isLoading: boolean;
  };
  handleUpdateTeacherMarkedChanges?: (
    fieldType: string | number,
    value: string | number
  ) => void;
  handleUpdateStudentFeedback: (data: any) => void;
}

export interface StudentViewProps {
  type: AnswerTypes;
  currentQuestionCount: number;
  handleQuestionCount: (value: string) => void;
  totalCount: number;
  homeworkAttemptDetails: any;
  questionDetails: any;
  studentName?: string;
  isLoading: boolean;
  handleCloseModal: () => void;
}

export interface SubmissionListProps {
  assignmentId: string;
}

export interface StudentSummaryListProps {
  studentList: any[];
  selectedStudents: string[];
  setSelectedStudents: (value: string[]) => void;
  handleUnpublishClick: (value: string[]) => void;
  handleSaveAndPublish: (
    summary: string,
    strength: string,
    weakness: string,
    homeworkSectionId: string
  ) => void;
  handlePublishStudentFeedback: (value: string[]) => void;
  handleConfirmReward: (value: string, point: string, response: string) => void;
}

export interface StudentSummaryCardProps {
  student: any;
  selectedStudents: string[];
  setSelectedStudents: (value: string[]) => void;
  handleUnpublishClick: (value: string[]) => void;
  handleSaveAndPublish: (
    summary: string,
    strength: string,
    weakness: string,
    homeworkSectionId: string
  ) => void;
  handlePublishStudentFeedback: (value: string[]) => void;
  handleConfirmReward: (value: string, point: string, response: string) => void;
}

export interface PublishFeedbackProps {
  selectedStudents: string[];
  selectedStudentFeedbackCount: number;
  selectedStudentCount: number;
  onCancel: (value: boolean) => void;
  onPublish: (value: string[]) => void;
}

export interface HomeworkOverviewProps {
  summary: any[];
  studentId: string;
  assignmentId: string;
  studentName: string;
}

export interface PerfomanceMetricsProps {
  title: string;
  subTitle?: string;
  primaryValue: number[];
  secondaryValue?: number[];
  showLabel?: boolean;
  labels: string[];
  backgroundColor: string[];
  type?: BarChartType;
  isHorizontal?: boolean;
  chartClassName?: string;
  barThickness?: number;
  dataLabelFontType?: 'mobile' | 'desktop';
  isLoading?: boolean;
}

export enum LabelOrientation {
  STACKED = 'STACKED',
  UNSTACKED = 'UNSTACKED'
}

export interface RoundChartMetricsProps {
  title: string;
  count?: string;
  subText?: string;
  values: number[];
  bgColors: string[];
  labels: string[];
  chartType?: PieChartType;
  labelOrientation?: LabelOrientation;
  className?: string;
  isLoading?: boolean;
}

export interface TextFieldProps {
  data: string;
  isLoading?: boolean;
  title: string;
  strength?: string;
  weakness?: string;
}

export interface TopicData {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  subjectId: string;
}

export interface SkillData {
  value: string;
  label: string;
  id: string;
  name: string;
  description: string;
  topicId: string;
  topics: TopicData;
}

export interface TopicSkills {
  [key: string]: string[];
}

export interface QuestionsAvailabilityModalProps {
  numberOfQuestions?: number;
  onConfirm: () => void;
}

export enum InfoCardVariant {
  BLUE,
  GREEN,
  YELLOW
}

export interface InfoCardProps {
  label: string;
  value: string | number;
  iconName: string;
  variant: InfoCardVariant;
  className?: string;
  valueClassName?: string;
}
