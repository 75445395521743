export const homeworkTabs = [
  {
    id: 1,
    label: 'Ongoing'
  },
  {
    id: 2,
    label: 'Completed'
  },
  {
    id: 3,
    label: 'All'
  }
];

export enum HomeWorkSummaryDetails {
  TOTAL_CLASSES = 'totalClasses',
  UPCOMINGDEADLINES = 'upcomingDeadLineCount',
  TOTAL_HOMEWORKS = 'totalHomeworks'
}

export const homeworkSummary = {
  [HomeWorkSummaryDetails.TOTAL_HOMEWORKS]: {
    id: 1,
    label: 'Total Homeworks',
    iconName: 'total-homework'
  },
  [HomeWorkSummaryDetails.UPCOMINGDEADLINES]: {
    id: 2,
    label: 'Upcoming Deadlines',
    iconName: 'total-subjects'
  },
  [HomeWorkSummaryDetails.TOTAL_CLASSES]: {
    id: 3,
    label: 'Classes',
    iconName: 'class'
  }
};

export const homeworkFilterOptions = [
  {
    id: 1,
    label: 'Subject and Levels',
    value: 'subjectAndLevels',
    isMultiLabel: false
  },
  {
    id: 2,
    label: 'Topic and Skills',
    value: 'topicAndSkills',
    isMultiLabel: true
  }
];

export const dummySubjectOptions = [
  {
    id: 1,
    label: 'Maths',
    value: 'maths'
  },
  {
    id: 2,
    label: 'Physics',
    value: 'physics'
  },
  {
    id: 3,
    label: 'English',
    value: 'english'
  }
];

export const changeParamOptions = [
  {
    id: 0,
    label: '0',
    value: '0'
  },
  {
    id: 1,
    label: '1',
    value: '1'
  },
  {
    id: 2,
    label: '2',
    value: '2'
  },
  {
    id: 3,
    label: '3',
    value: '3'
  },
  {
    id: 4,
    label: '4',
    value: '4'
  },
  {
    id: 5,
    label: '5',
    value: '5'
  },
  {
    id: 6,
    label: '6',
    value: '6'
  },
  {
    id: 7,
    label: '7',
    value: '7'
  },
  {
    id: 8,
    label: '8',
    value: '8'
  },
  {
    id: 9,
    label: '9',
    value: '9'
  },
  {
    id: 10,
    label: '10',
    value: '10'
  }
];

export const questionTypeOptions = [
  {
    key: 'mcq',
    value: 'MCQ'
  },
  {
    key: 'sa',
    value: 'SA'
  },
  {
    key: 'la',
    value: 'LA'
  }
];

export const difficultyOptions = [
  {
    key: 'a01',
    value: 'A01'
  },
  {
    key: 'a02',
    value: 'A02'
  },
  {
    key: 'a03',
    value: 'A03'
  }
];

export enum QuestionTypes {
  SA = 'Short Answer',
  LA = 'Long Answer',
  MCQ = 'MCQ'
}

export enum Difficulty {
  A01 = 'AO1',
  A02 = 'AO2',
  A03 = 'AO3'
}

export const paginationLimit = 12;

export enum FilterOptions {
  SUBJECT = 'subject',
  SUBJECT_AND_LEVELS = 'subjectAndLevels',
  TOPIC_AND_SKILLS = 'topicAndSkills'
}

export const homeworkDetailsTabs = [
  {
    id: 1,
    label: 'Details'
  },
  {
    id: 2,
    label: 'Submission'
  }
];

export const CompletedHomeworkDetailsTabs = [
  {
    id: 1,
    label: 'Details'
  },
  {
    id: 2,
    label: 'Submission'
  },
  {
    id: 3,
    label: 'Summary'
  }
];

export enum StudentHomeworkViewType {
  RESULT_VIEW = 'RESULT_VIEW',
  STUDENT_VIEW = 'STUDENT_VIEW'
}

export enum AnswerTypes {
  LONG_ANSWER = 'Long Answer',
  SHORT_ANSWER = 'Short Answer',
  MCQ = 'MCQ'
}

export const SubmissionTableHeaders = [
  {
    Header: 'STUDENT NAME',
    accessor: 'name',
    width: '20%',
    sort: false
  },
  {
    Header: 'HOMEWORK STATUS',
    accessor: 'homeworkStatus',
    width: '17%',
    sort: true
  },
  {
    Header: 'SCORE',
    accessor: 'score',
    width: '17%',
    sort: true
  },
  {
    Header: 'SUBMISSION DATE',
    accessor: 'date',
    width: '18%',
    sort: true
  },
  {
    Header: 'COMPLETION TIME',
    accessor: 'completionTime',
    width: '18%',
    sort: true
  },
  {
    Header: 'ACTIONS',
    accessor: 'actions',
    width: '10%',
    sort: false
  }
];

export const progressData = {
  bgColor: ['#7C57FF', '#9679FF', '#E5DDFF'],
  title: 'Progress',
  subText: 'Students in class',
  labels: ['Fully submitted', 'Partially submitted', 'Not submitted']
};

export const questionTypePerfomanceData = {
  title: 'Question Type Performance',
  subTitle: 'Success Rate (%) / Question Type',
  bgColor: ['#CBBCFF', '#CBBCFF', '#CBBCFF'],
  labels: ['MCQ', 'SA', 'LA']
};

export const overallPerformanceData = {
  title: 'Overall Performance',
  subTitle: 'Percentage of Students (%) / Score',
  bgColor: ['#CBE8C1', '#B9DDFE', '#FFE8C6', '#FFDFDA'],
  labels: [
    'Outstanding (80+)',
    'Strong (60+)',
    'Satisfactory (40+)',
    'Needs Attention (0-40)'
  ]
};

export const averageCompletionTimeData = {
  title: 'Average Completion Time',
  subTitle: 'Minutes / Question Number',
  bgColor: ['#B9DDFE']
};

export const underperformingQuestionsData = {
  title: 'Underperforming Questions',
  subTitle: 'Wrong & Partially Correct Responses / Question Number',
  bgColor: ['#CBE8C1', '#ECC5D3']
};

export enum EducatorEditFields {
  FEEDBACK = 'feedback',
  MARKS = 'marks'
}
export enum PreviewModalTypes {
  DISTRIBUTE = 'DISTRIBUTE',
  QUESTION_DETAIL = 'QUESTION_DETAIL',
  CHANGE_PARAM = 'CHANGES_PARAM',
  CHANGE_QUESTION = 'CHANGE_QUESTION',
  REPLACE_QUESTION = 'REPLACE_QUESTION',
  CHANGE_QUESTION_LIST = 'CHANGE_QUESTION_LIST',
  QUESTION_AVAILABILITY = 'QUESTION_AVAILABILITY'
}
export const previewModalTitles = {
  [PreviewModalTypes.DISTRIBUTE]: 'Distribute',
  [PreviewModalTypes.QUESTION_DETAIL]: 'Details',
  [PreviewModalTypes.CHANGE_PARAM]: 'Change Questions',
  [PreviewModalTypes.CHANGE_QUESTION]: 'Change Questions',
  [PreviewModalTypes.REPLACE_QUESTION]: 'Replace Question',
  [PreviewModalTypes.CHANGE_QUESTION_LIST]: 'Change Question'
  // [PreviewModalTypes.QUESTION_AVAILABILITY]: ''
};

export const strongAreaData = {
  bgColor: ['#B2DDA3', '#FFD18D', '#72BCFD'],
  title: 'Areas of Strength',
  labels: ['Topic Name1', 'Topic Name2', 'Topic Name3']
};

export const weakAreaData = {
  bgColor: ['#E38F82', '#FEBEB5', '#FFDFDA'],
  title: 'Areas of Growth',
  labels: ['Topic Name1', 'Topic Name2', 'Topic Name3']
};

export const requiredReportKeys = [
  'recommendation',
  'strongSkills',
  'weakSkills',
  'questionTypePerformance',
  'overallPerformance',
  'averageCompletionTimeOfQuestions',
  'underPerformingQuestions',
  'topPerformingStudents',
  'needsAttentionStudents'
];

export const dummyStudentSummary = [
  {
    id: '1',
    name: 'Kelvin Tromp',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec eros felis. Morbi vel dapibus ipsum. Nulla a dignissim enim. Phasellus faucibus risus eget lectus mattis, vitae venenatis odio porttitor. Nam massa eros, sollicitudin quis efficitur nec, laoreet sed augue. Cras molestie pretium magna non fringilla.',
    strength: 'Linear equations, Theorems and proofs, Poetry analysis:',
    weakness: 'Creative writing, Modern history',
    isPublished: false
  },
  {
    id: '2',
    name: 'Kevlar',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec eros felis. Morbi vel dapibus ipsum. Nulla a dignissim enim. Phasellus faucibus risus eget lectus mattis, vitae venenatis odio porttitor. Nam massa eros, sollicitudin quis efficitur nec, laoreet sed augue. Cras molestie pretium magna non fringilla.',
    strength: 'Linear equations, Theorems and proofs, Poetry analysis:',
    weakness: 'Creative writing, Modern history',
    isPublished: false
  },
  {
    id: '3',
    name: 'Top Queen',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec eros felis. Morbi vel dapibus ipsum. Nulla a dignissim enim. Phasellus faucibus risus eget lectus mattis, vitae venenatis odio porttitor. Nam massa eros, sollicitudin quis efficitur nec, laoreet sed augue. Cras molestie pretium magna non fringilla.',
    strength: 'Linear equations, Theorems and proofs, Poetry analysis:',
    weakness: 'Creative writing, Modern history',
    isPublished: true
  },
  {
    id: '4',
    name: 'Kill the pain',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec eros felis. Morbi vel dapibus ipsum. Nulla a dignissim enim. Phasellus faucibus risus eget lectus mattis, vitae venenatis odio porttitor. Nam massa eros, sollicitudin quis efficitur nec, laoreet sed augue. Cras molestie pretium magna non fringilla.',
    strength: 'Linear equations, Theorems and proofs, Poetry analysis:',
    weakness: 'Creative writing, Modern history',
    isPublished: false
  },
  {
    id: '5',
    name: 'Vishal',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec eros felis. Morbi vel dapibus ipsum. Nulla a dignissim enim. Phasellus faucibus risus eget lectus mattis, vitae venenatis odio porttitor. Nam massa eros, sollicitudin quis efficitur nec, laoreet sed augue. Cras molestie pretium magna non fringilla.',
    strength: 'Linear equations, Theorems and proofs, Poetry analysis:',
    weakness: 'Creative writing, Modern history',
    isPublished: false
  },
  {
    id: '6',
    name: 'Mascot',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec eros felis. Morbi vel dapibus ipsum. Nulla a dignissim enim. Phasellus faucibus risus eget lectus mattis, vitae venenatis odio porttitor. Nam massa eros, sollicitudin quis efficitur nec, laoreet sed augue. Cras molestie pretium magna non fringilla.',
    strength: 'Linear equations, Theorems and proofs, Poetry analysis:',
    weakness: 'Creative writing, Modern history',
    isPublished: false
  },
  {
    id: '7',
    name: 'Tromp Queen',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec eros felis. Morbi vel dapibus ipsum. Nulla a dignissim enim. Phasellus faucibus risus eget lectus mattis, vitae venenatis odio porttitor. Nam massa eros, sollicitudin quis efficitur nec, laoreet sed augue. Cras molestie pretium magna non fringilla.',
    strength: 'Linear equations, Theorems and proofs, Poetry analysis:',
    weakness: 'Creative writing, Modern history',
    isPublished: false
  },
  {
    id: '8',
    name: 'Hema',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec eros felis. Morbi vel dapibus ipsum. Nulla a dignissim enim. Phasellus faucibus risus eget lectus mattis, vitae venenatis odio porttitor. Nam massa eros, sollicitudin quis efficitur nec, laoreet sed augue. Cras molestie pretium magna non fringilla.',
    strength: 'Linear equations, Theorems and proofs, Poetry analysis:',
    weakness: 'Creative writing, Modern history',
    isPublished: false
  }
];
